import { useState } from "react"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { FormatDateMDY } from "../utils/Date"

const columns = [
    { header: 'Productor', key: 'code' },
    { header: 'Fecha', key: 'date' },
    { header: 'AM', key: 'am' },
    { header: 'PM', key: 'pm' }
]

const workSheetName = 'Hoja1'
const workBookName = 'captura acopio de leche'
const myInputId = 'myInput'

const ReportAccountingArco = () => {
    const [initialdate, setInitialdate] = useState('')
    const [endDate, setEndDate] = useState('')

    const workbook = new Excel.Workbook();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const token = sessionStorage.getItem('token')
            const config = AxiosHeader(token)
            const {data} = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/registry/report-arco`,
                {
                    initialdate,
                    endDate
                },
                config
            )

            const myInput = document.getElementById(myInputId);
            const fileName = myInput.value || workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            data.forEach(singleData => {
                singleData.date = FormatDateMDY(singleData.date)
                worksheet.addRow(singleData);
            });

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);

        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    }

    return (
        <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Reporte contabilidad Arco</h1>

                <form onSubmit={handleSubmit}>
                    <div className="container text-center mb-5">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="initialdate"
                                >Fecha inicio</label>
                                <input
                                    type="date"
                                    id="initialdate"
                                    name="initialdate"
                                    className="form-control"
                                    value={initialdate}
                                    onChange={e => setInitialdate(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="enddate"
                                >Fecha fin</label>
                                <input
                                    type="date"
                                    id="enddate"
                                    name="enddate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label
                                    className="form-label"
                                    htmlFor={myInputId}
                                >Nombre del archivo</label>
                                <input
                                    type="text"
                                    id={myInputId}
                                    className="form-control"
                                    defaultValue={workBookName}
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <button className="btn btn-primary">Generar</button>
                            </div>
                        </div>
                    </div>
                </form>
			</div>
		</div>
    )
}

export default ReportAccountingArco
import { useState } from "react"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { FormatDateMDY } from "../utils/Date"

const columns = [
	{ header: 'Código tanque', key: 'code' },
    { header: 'Tanque', key: 'user_tank_name' },
    { header: 'NIT', key: 'nit' },
    { header: 'Cliente', key: 'customer_name' },
    { header: 'Estado', key: 'quality_status' },
    { header: 'Fecha liquidación', key: 'liquidation_date' },
    { header: 'Desde', key: 'liquidated_from' },
    { header: 'Hasta', key: 'liquidated_until' },
    { header: 'Proteína 1', key: 'protein_1' },
    { header: 'Proteína 2', key: 'protein_2' },
    { header: 'Proteína 3', key: 'protein_3' },
    { header: 'Proteína promedio', key: 'protein_average' },
    { header: 'Grasa 1', key: 'grease_1' },
    { header: 'Grasa 2', key: 'grease_2' },
    { header: 'Grasa 3', key: 'grease_3' },
    { header: 'Grasa promedio', key: 'grease_average' },
    { header: 'Solidos 1', key: 'solids_1' },
    { header: 'Solidos 2', key: 'solids_2' },
    { header: 'Solidos 3', key: 'solids_3' },
    { header: 'Solidos promedio', key: 'solids_average' },
    { header: 'MUN 1', key: 'mun_1' },
    { header: 'MUN 2', key: 'mun_2' },
    { header: 'MUN 3', key: 'mun_3' },
    { header: 'MUN promedio', key: 'mun_average' },
    { header: 'RCS 1', key: 'rcs_1' },
    { header: 'RCS 2', key: 'rcs_2' },
    { header: 'RCS 3', key: 'rcs_3' },
    { header: 'RCS promedio', key: 'rcs_average' },
    { header: 'Densidad 1', key: 'density_1' },
    { header: 'Densidad 2', key: 'density_2' },
    { header: 'Densidad 3', key: 'density_3' },
    { header: 'Densidad promedio', key: 'density_average' },
    { header: 'UFC 1', key: 'ufc_1' },
    { header: 'UFC 2', key: 'ufc_2' },
    { header: 'UFC 3', key: 'ufc_3' },
    { header: 'UFC promedio', key: 'ufc_average' },
    { header: 'Calidad higiénica 1', key: 'hygienic_1' },
    { header: 'Calidad higiénica 2', key: 'hygienic_2' },
    { header: 'Calidad higiénica 3', key: 'hygienic_3' },
    { header: 'Calidad higiénica promedio', key: 'hygienic_average' }
]

const workSheetName = 'Hoja1'
const workBookName = 'calidad externa'
const myInputId = 'myInput'

const ReportExternalQuality = () => {
    const [initialdate, setInitialdate] = useState('')
    const [endDate, setEndDate] = useState('')

    const workbook = new Excel.Workbook();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const token = sessionStorage.getItem('token')
            const config = AxiosHeader(token)
            const {data} = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/external-quality/get-all-by-date`,
                {
                    initialdate,
                    endDate
                },
                config
            )

            const myInput = document.getElementById(myInputId);
            const fileName = myInput.value || workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            data.forEach(singleData => {
                singleData.date = FormatDateMDY(singleData.date)
                worksheet.addRow(singleData);
            });

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);

        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    }

    return (
        <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Reporte calidad externa</h1>

                <form onSubmit={handleSubmit}>
                    <div className="container text-center mb-5">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="initialdate"
                                >Fecha inicio</label>
                                <input
                                    type="date"
                                    id="initialdate"
                                    name="initialdate"
                                    className="form-control"
                                    value={initialdate}
                                    onChange={e => setInitialdate(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="enddate"
                                >Fecha fin</label>
                                <input
                                    type="date"
                                    id="enddate"
                                    name="enddate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label
                                    className="form-label"
                                    htmlFor={myInputId}
                                >Nombre del archivo</label>
                                <input
                                    type="text"
                                    id={myInputId}
                                    className="form-control"
                                    defaultValue={workBookName}
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <button className="btn btn-primary">Generar</button>
                            </div>
                        </div>
                    </div>
                </form>
			</div>
		</div>
    )
}

export default ReportExternalQuality
class Message {

    static ENTER_LOGIN = '¡Ingrese todos los datos!'

    static ENTER_PASSWORD = '¡Ingrese la contraseña!'

    static BAD_URL = '¡La ruta ingresada es erronea!'

    static EMPTY_FIELDS = '¡Todos los campos son requeridos!'
}

export default Message
import { useEffect, useState } from "react"
import Alert from "../components/common/Alert"
import { Link } from "react-router-dom"
import axios from "axios"
import AxiosHeader from "../utils/AxiosHeader"

const ProducerAdd = () => {
    const [alert, setAlert] = useState({})
    const [tanks, setTanks] = useState([])
    const [code, setCode] = useState('')
    const [nit, setNit] = useState('')
    const [name, setName] = useState('')
    const [userTank, setUserTank] = useState('')

    const token = sessionStorage.getItem('token')
	const config = AxiosHeader(token)

    useEffect(() => {
        const getUserTank = async () => {
			try {
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/user-tank/findall`,
					config
				)
				setTanks(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getUserTank()
    }, [])

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setAlert({})
            const {data} = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/producer/add`,
                {
                    code,
                    nit,
                    name,
                    userTank
                },
                config
            )
            setAlert({
                msg: data.msg,
                error: false
            })
            setTimeout(() => {
                setAlert({})
            }, 5000);
        } catch (error) {
            setAlert({
                msg: error.response.data.msg,
                error: true
            })
        }
    }

    return (
        <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
            <div className="form-dashboard">
                <h1 className="h1-dashboard">Agregar productor</h1>

                <br />

                <div className="text-center">
                    {alert.msg && <Alert alert={alert}></Alert>}
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="container text-center mt-2">
                        <div className="row mb-1">
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="code"
                                    className="form-label"
                                >Código</label>
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    className="form-control"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="nit"
                                    className="form-label"
                                >Nit</label>
                                <input
                                    type="text"
                                    id="nit"
                                    name="nit"
                                    className="form-control"
                                    value={nit}
                                    onChange={e => setNit(e.target.value)}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >Nombre</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >Tanque</label>
                                <select
                                    id="tank"
                                    name="tank"
                                    className="form-select"
                                    onChange={e => setUserTank(e.target.value)}
                                    defaultValue="DEFAULT"
                                >
                                    <option value="DEFAULT" disabled>Seleccione una opción</option>
                                    {
                                        tanks.map((tank, index) => (
                                            <option key={index} value={tank.oid}>{tank.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <br />
					<div className="container text-center mb-5">
						<div className="row mb-3">
							<div className="col-12 col-sm-6">
								<button className='btn btn-primary'>Guardar</button>
							</div>
							<div className='col-12 col-sm-6'>
								<Link to='/dashboard-admin/producer' className='btn btn-secondary'>Regresar</Link>
							</div>
						</div>
					</div>
                </form>
            </div>
        </div>
    )
}

export default ProducerAdd
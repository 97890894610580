import { useState } from "react"
import { Link } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import Alert from "../components/common/Alert"

const UserTankAdd = () => {
	const [code, setCode] = useState('')
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const [alert, setAlert] = useState({})

	const handleSubmit = async (e) => {
		try {
			e.preventDefault()
			setAlert({})
			const token = sessionStorage.getItem('token')
			const config = AxiosHeader(token)
			const {data} = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/user-tank/add`,
				{
					code,
					name,
					password
				},
				config
			)
			setAlert({
				msg: data.msg,
				error: false
			})
			setTimeout(() => {
				setAlert({})
			}, 5000);
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
	}

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Agregar usuario tanque</h1>

				<br />

				<div className="text-center">
					{alert.msg && <Alert alert={alert}></Alert>}
				</div>

				<form onSubmit={handleSubmit}>
					<div className="container text-center mt-2">
						<div className="row mb-1">
							<div className="col-12 col-md-4">
								<label
									htmlFor="code"
									className="form-label"
								>Código</label>
								<input
									type="text"
									id="code"
									name="code"
									className="form-control"
									value={code}
									onChange={e => setCode(e.target.value)}
									required
								></input>
							</div>
							<div className="col-12 col-md-4">
								<label
									htmlFor="name"
									className="form-label"
								>Nombre</label>
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									value={name}
									onChange={e => setName(e.target.value)}
									required
								></input>
							</div>
							<div className="col-12 col-md-4">
								<label
									htmlFor="password"
									className="form-label"
								>Contraseña</label>
								<input
									type="text"
									id="password"
									name="password"
									className="form-control"
									value={password}
									onChange={e => setPassword(e.target.value)}
									required
								></input>
							</div>
						</div>
					</div>
					<br />
					<div className="container text-center mb-5">
						<div className="row mb-3">
							<div className="col-12 col-sm-6">
								<button className='btn btn-primary'>Guardar</button>
							</div>
							<div className='col-12 col-sm-6'>
								<Link to='/dashboard-admin/user-tank' className='btn btn-secondary'>Regresar</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default UserTankAdd
import logo from '../../assets/images/alagro.png'

const Header = () => {
	return (
		<div className="authheader col-12">
			<img src={logo} alt='Logo' className='img-authtankheader'></img>
		</div>
	)
}

export default Header
import { Outlet } from "react-router-dom"
import Header from "../common/Header"

const AuthLayout = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<Header></Header>
			</div>
			<div className="row">
				<Outlet></Outlet>
			</div>
		</div>
	)
}

export default AuthLayout
import { Link } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import { useEffect, useState } from "react"
import axios from "axios"
import Alert from "../components/common/Alert"

const ExternalQuality = () => {
	const [alert, setAlert] = useState({})
	const [discard, setDiscard] = useState('')
	const [registries, setRegistries] = useState([])

	useEffect(() => {
		const getExternalQuality = async () => {
			try {
				const token = sessionStorage.getItem('token')
				const config = AxiosHeader(token)
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/external-quality/find-all`,
					config
				)
				setRegistries(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getExternalQuality()
	}, [alert])

	const discardClick = async (e) => {
		e.preventDefault()
		setAlert({})
		const token = sessionStorage.getItem('token')
		const config = AxiosHeader(token)
		const {data} = await axios.post(
			`${process.env.REACT_APP_URL_SERVER}/external-quality/discard`,
			{
				discard
			},
			config
		)
		setAlert({
			msg: data.msg,
			error: false
		})
		setTimeout(() => {
			setAlert({})
		}, 5000);
	}

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Calidad composicional externa</h1>

				{alert.msg && <Alert alert={alert}></Alert>}

				<div className="container text-center mb-5">
					<div className="row mb-3">
						<div className="col-12">
							<Link to='/dashboard-admin/external-quality-add' className='btn btn-primary'>Crear registro</Link>
						</div>
					</div>
				</div>

				<hr />

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Tanque</th>
							<th scope="col">Cliente</th>
							<th scope="col">Fecha liquidación</th>
							<th scope="col">Fecha desde</th>
							<th scope="col">Fecha hasta</th>
							<th scope="col">Fecha creación</th>
							{/* <th scope="col">Actualizar</th> */}
							<th scope="col">Anular</th>
						</tr>
					</thead>
					<tbody>
						{
							registries.map((registry, index) => (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>{registry.usertank}</td>
									<td>{registry.customer}</td>
									<td>{registry.liquidation_date}</td>
									<td>{registry.liquidated_from}</td>
									<td>{registry.liquidated_until}</td>
									<td>{registry.created_at}</td>

									{/* <td><Link to={`/dashboard-admin/internal-quality-add/`} className='btn btn-primary'></Link></td> */}

									<td><button type='button' className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#discardModal" onClick={e => setDiscard(registry.oid)}></button></td>

									<div className="modal fade" id="discardModal" tabIndex="-1" aria-labelledby="discardModalLabel" aria-hidden="true">
										<div className="modal-dialog">
											<div className="modal-content">
											<div className="modal-header">
												<h1 className="modal-title fs-5" id="discardModalLabel">Descartar registro</h1>
												<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
											</div>
											<div className="modal-body">
												Desea descartar el registro?
											</div>
											<div className="modal-footer">
												<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
												<button type="button" className="btn btn-primary" onClick={discardClick} data-bs-dismiss="modal">Descartar</button>
											</div>
											</div>
										</div>
									</div>
								</tr>
							))
						}
					</tbody>
				</table>

			</div>
		</div>
	)
}

export default ExternalQuality
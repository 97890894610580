import { useEffect, useState } from "react"
import axios from "axios";
import AxiosHeader from "../utils/AxiosHeader"
import {CurrentDateOnlyDate} from "../utils/Date";
import Alert from "../components/common/Alert";

const DashboardTank = () => {
	const [date, setDate] = useState(CurrentDateOnlyDate())
	const [registry, setRegistry] = useState([])
	const [registries, setRegistries] = useState({})
	const [totalAm, setTotalAm] = useState(0)
	const [totalPm, setTotalPm] = useState(0)
	const [alert, setAlert] = useState({})

	useEffect(() => {
		const getAllRegistries = async () => {
			try {
				setRegistry([])
				setRegistries({})
				const token = sessionStorage.getItem('token-tank')
				const config = AxiosHeader(token)
				const {data} = await axios.post(
					`${process.env.REACT_APP_URL_SERVER}/registry/findall-tank`,
					{
						date
					},
					config
				)
				setRegistry(data)
				let am = 0
				let pm = 0
				for (let i = 0; i < data.length; i++) {
					am = am + data[i].am
					pm = pm + data[i].pm
				}
				setTotalAm(am)
				setTotalPm(pm)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getAllRegistries()
	}, [date])

	const onDateChange = (e) => {
		setDate(e.target.value);
	}

	const onChangeAm = (e, index) => {
		e.target.name = e.target.value
		registry[index].am = e.target.name
		setRegistries({
			registry
		})
		let am = 0
		for (let i = 0; i < registry.length; i++) {
			am = am + parseInt(registry[i].am)
		}
		setTotalAm(am)
	}

	const onChangePm = (e, index) => {
		e.target.name = e.target.value
		registry[index].pm = e.target.name
		setRegistries({
			registry
		})
		let pm = 0
		for (let i = 0; i < registry.length; i++) {
			pm = pm + parseInt(registry[i].pm)
		}
		setTotalPm(pm)
	}

	const handlerSubmit = async (e) => {
		try {
			e.preventDefault()
			const registriesNew = {
				registry: registry,
				date: date
			}
			const token = sessionStorage.getItem('token-tank')
			const config = AxiosHeader(token)
			const {data} = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/registry/add`,
				registriesNew
				,
				config
			)
			setAlert({
				msg: data.msg,
				error: false
			})
			setTimeout(() => {
				setAlert({})
			}, 5000);
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
	}

	return (
		<div className="dashboardtank col-12 col-sm-9">
			<h1 className="h1-dashboardtank">Formulario</h1>

			<div className="form-dashboardtank">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<input
								type="date"
								className="date-dashboardtank form-control"
								value={date}
								onChange={onDateChange}
							></input>
						</div>
					</div>
					<div className="row">
						<div className="subtitle-dashboardtank col-12">
							<p>Productores</p>
						</div>
					</div>
					<form onSubmit={handlerSubmit}>
						{
							registry
							?
							registry.map((reg, index) => (
								<div className="row align-items-center" key={index}>
									<div className="col-6">
										<p>{reg.producer}</p>
									</div>
									<div className="col-3 text-center">
										<label htmlFor={'am' + index}>A.M.</label>
										{
											reg.statusAm > 2
											?
											<input
												type="text"
												id={'am' + index}
												name={'am' + index}
												key={index}
												className="hour-dashboardtank width-dashboardtank form-control"
												value={reg.am}
												onChange={e => onChangeAm(e, index)}
												min={0}
												disabled
											></input>
											:
											<input
												type="text"
												id={'am' + index}
												name={'am' + index}
												key={index}
												className="hour-dashboardtank width-dashboardtank form-control"
												value={reg.am}
												onChange={e => onChangeAm(e, index)}
												min={0}
												required
											></input>
										}
									</div>
									<div className="col-3 text-center">
										<label htmlFor={'pm' + index}>P.M.</label>
										{
											reg.statusPm > 2
											?
											<input
												type="text"
												id={'pm' + index}
												name={'pm' + index}
												key={index}
												className="hour-dashboardtank width-dashboardtank form-control"
												value={reg.pm}
												onChange={e => onChangePm(e, index)}
												min={0}
												disabled
											></input>
											:
											<input
												type="text"
												id={'pm' + index}
												name={'pm' + index}
												key={index}
												className="hour-dashboardtank width-dashboardtank form-control"
												value={reg.pm}
												onChange={e => onChangePm(e, index)}
												min={0}
												required
											></input>
										}
									</div>
								</div>
							))
							:
							''
						}

						{alert.msg && <Alert alert={alert}></Alert>}

						<div className="row align-items-center">
								<div className="col-6">
									</div>
									<div className="col-3 text-center">
										<label htmlFor='am'>Total A.M.</label>
										<input
											type="text"
											id='am'
											name='am'
											className="hour-dashboardtank width-dashboardtank form-control"
											value={totalAm}
											onChange={e => setTotalAm(e.target.value)}
											disabled
										></input>
									</div>
									<div className="col-3 text-center">
										<label htmlFor='pm'>Total P.M.</label>
										<input
											type="text"
											id='pm'
											name='pm'
											className="hour-dashboardtank width-dashboardtank form-control"
											value={totalPm}
											onChange={e => setTotalPm(e.target.value)}
											disabled
										></input>
									</div>
								</div>
						<div className="row">
							<div className="col-12">
							<button className="btn-dashboardtank btn btn-primary btn-block mb-4">Guardar</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default DashboardTank
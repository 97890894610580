import { useEffect, useState } from "react"
import Alert from "../components/common/Alert"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import { Link, useParams } from "react-router-dom"

const AdministrationAdjustment = () => {
    const [alert, setAlert] = useState({})
    const [registry, setRegistry] = useState({})
    const [amount, setAmount] = useState('')
    const [control, setControl] = useState('')

    const {oid} = useParams()

    useEffect(() => {
        const getRegistry = async () => {
            try {
                const token = sessionStorage.getItem('token')
                const config = AxiosHeader(token)
                const {data} = await axios.get(
                    `${process.env.REACT_APP_URL_SERVER}/registry/find-adjustment/${oid}`,
                    config
                )
                setRegistry(data[0])
            } catch (error) {
                setAlert({
                    msg: error.response.data.msg,
                    error: true
                })
            }
        }
        getRegistry()
        setControl('')
    }, [control])

    const handlerSubmit = async(e) => {
        try {
            e.preventDefault()
            setAlert({})
            const oidtank = registry.oidtank
            const token = sessionStorage.getItem('token')
            const config = AxiosHeader(token)
            const {data} = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/registry/adjustment-amout`,
                {
                    oid,
                    amount,
                    oidtank
                },
                config
            )
            setAlert({
                msg: data.msg,
                error: false
            })
            setControl('1')
            setTimeout(() => {
                setAlert({})
            }, 5000);

        } catch (error) {
            setAlert({
                msg: error.response.data.msg,
                error: true
            })
        }
    }

    return (
        <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
            <div className="form-dashboard">
                <h1 className="h1-dashboard">Ajuste de cantidad</h1>

                <div className="text-center">
					{alert.msg && <Alert alert={alert}></Alert>}
				</div>

                <div>
                    <div className="container text-center mb-5">
                        <div className="row mb-3">
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="producer"
                                    className='form-label'
                                >Productor</label>
                                <input
                                    type='text'
                                    id='producer'
                                    name='producer'
                                    className='form-control'
                                    value={registry.producer}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="tank"
                                    className='form-label'
                                >Tanque</label>
                                <input
                                    type='text'
                                    id='tank'
                                    name='tank'
                                    className='form-control'
                                    value={registry.usertank}
                                    disabled
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <label
                                    htmlFor="date"
                                    className='form-label'
                                >Fecha</label>
                                <input
                                    type='text'
                                    id='date'
                                    name='date'
                                    className='form-control'
                                    value={registry.date}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-12 col-md-4">
                                <label
                                    htmlFor="hour"
                                    className='form-label'
                                >Hora</label>
                                <input
                                    type='text'
                                    id='hour'
                                    name='hour'
                                    className='form-control'
                                    value={registry.hour === 0 ? 'AM' : 'PM'}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-12 col-md-4">
                                <label
                                    htmlFor="amount"
                                    className='form-label'
                                >Cantidad actual</label>
                                <input
                                    type='text'
                                    id='amount'
                                    name='amount'
                                    className='form-control'
                                    value={registry.amount}
                                    disabled
                                ></input>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="container text-center mt-5">
                        <form onSubmit={handlerSubmit}>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label
                                        htmlFor="amount"
                                        className='form-label'
                                    >Ingresa la nueva cantidad</label>
                                    <input
                                        type='number'
                                        id='amount'
                                        name='amount'
                                        className='form-control'
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                        required
                                    ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-6'>
                                    <button className='btn btn-primary'>Guardar</button>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <Link to='/dashboard-admin/administration' className='btn btn-secondary'>Regresar</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdministrationAdjustment
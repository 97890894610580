import { Link } from "react-router-dom"

const Configuration = () => {

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Configuración</h1>

				<div className="container text-center mt-2">
					<div className="row mb-1">
						<div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Usuarios tanques</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/user-tank' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Productores</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/producer' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
                    </div>
                    {/* <div className="row mb-1">
						<div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Usuarios administración</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='#' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
                        <div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Usuarios Datalogger</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='#' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</form>
		</div>
	)
}

export default Configuration
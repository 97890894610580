import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AxiosHeader from '../utils/AxiosHeader'
import axios from 'axios'
import Alert from '../components/common/Alert'
import Message from '../utils/Message'

const InternalQualityAdd = () => {
	const [alert, setAlert] = useState({})
	const [registry, setRegistry] = useState({})
	const [internal, setInternal] = useState({})
	const [badUrl, setBadUrl] = useState(0)
	const [temperature, setTemperature] = useState('')
	const [density, setDensity] = useState('')
	const [grease, setGrease] = useState('')
	const [protein, setProtein] = useState('')
	const [lactose, setLactose] = useState('')
	const [sng, setSng] = useState('')
	const [st, setSt] = useState('')
	const [water, setWater] = useState('')
	const [acidity, setAcidity] = useState('')
	const [cmt, setCmt] = useState('')
	const [cryoscopy, setCryoscopy] = useState('')
	const [ufc, setUfc] = useState('')

	const {oid} = useParams()

	useEffect(() => {
		const getInternalQuality = async () => {
			try {
				const token = sessionStorage.getItem('token')
				const config = AxiosHeader(token)
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/internal-quality/findAll-registry/${oid}`,
					config
				)
				if (!data.registry[0]) {
					setAlert({
						msg: Message.BAD_URL,
						error: true
					})
					return
				}
				setBadUrl(1)
				setRegistry(data.registry[0])
				if (data.internal) {
					setTemperature(data.internal.temperature)
					setDensity(data.internal.density)
					setGrease(data.internal.grease)
					setProtein(data.internal.protein)
					setLactose(data.internal.lactose)
					setSng(data.internal.sng)
					setSt(data.internal.st)
					setWater(data.internal.water)
					setAcidity(data.internal.acidity)
					setCmt(data.internal.cmt)
					setCryoscopy(data.internal.cryoscopy)
					setUfc(data.internal.ufc)
				} else {
					setAcidity(null)
					setCmt(null)
				}
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getInternalQuality()
	}, [])

	const handlerSubmit = async (e) => {
		try {
			e.preventDefault()
			setAlert({})
			if (acidity === null || cmt === null) {
				setAlert({
					msg: Message.EMPTY_FIELDS,
					error: true
				})
				return
			}
			const token = sessionStorage.getItem('token')
			const config = AxiosHeader(token)
			const {data} = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/internal-quality/add`,
				{
					oid,
					temperature,
					density,
					grease,
					protein,
					lactose,
					sng,
					st,
					water,
					acidity,
					cmt,
					cryoscopy,
					ufc
				},
				config
			)
			setAlert({
				msg: data.msg,
				error: false
			})
			setTimeout(() => {
				setAlert({})
			}, 5000);
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
	}

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Agregar calidad composicional</h1>

				<div className="text-center">
					{alert.msg && <Alert alert={alert}></Alert>}
				</div>

				{
					badUrl === 1
					?
					<div>
						<div className="container text-center mb-5">
							<div className="row mb-3">
								<div className="col-12 col-md-6">
									<label
										htmlFor="producer"
										className='form-label'
									>Productor</label>
									<input
										type='text'
										id='producer'
										name='producer'
										className='form-control'
										value={registry.producer}
										disabled
									></input>
								</div>
								<div className="col-12 col-md-6">
									<label
										htmlFor="tank"
										className='form-label'
									>Tanque</label>
									<input
										type='text'
										id='tank'
										name='tank'
										className='form-control'
										value={registry.usertank}
										disabled
									></input>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4">
									<label
										htmlFor="date"
										className='form-label'
									>Fecha</label>
									<input
										type='text'
										id='date'
										name='date'
										className='form-control'
										value={registry.date}
										disabled
									></input>
								</div>
								<div className="col-12 col-md-4">
									<label
										htmlFor="hour"
										className='form-label'
									>Hora</label>
									<input
										type='text'
										id='hour'
										name='hour'
										className='form-control'
										value={registry.hour === 0 ? 'AM' : 'PM'}
										disabled
									></input>
								</div>
								<div className="col-12 col-md-4">
									<label
										htmlFor="amount"
										className='form-label'
									>Cantidad</label>
									<input
										type='text'
										id='amount'
										name='amount'
										className='form-control'
										value={registry.amount}
										disabled
									></input>
								</div>
							</div>
						</div>
						<hr />
						<div className="container text-center mt-5">
							<form onSubmit={handlerSubmit}>
								<div className="row mb-3">
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="temperature"
											className='form-label'
										>Temperatura</label>
										<input
											type='number'
											id='temperature'
											name='temperature'
											className='form-control'
											value={temperature}
											onChange={e => setTemperature(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="density"
											className='form-label'
										>Densidad</label>
										<input
											type='number'
											id='density'
											name='density'
											className='form-control'
											value={density}
											onChange={e => setDensity(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="grease"
											className='form-label'
										>Grasa</label>
										<input
											type='number'
											id='grease'
											name='grease'
											className='form-control'
											value={grease}
											onChange={e => setGrease(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="protein"
											className='form-label'
										>Proteína</label>
										<input
											type='number'
											id='protein'
											name='protein'
											className='form-control'
											value={protein}
											onChange={e => setProtein(e.target.value)}
											required
										></input>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="lactose"
											className='form-label'
										>Lactosa</label>
										<input
											type='number'
											id='lactose'
											name='lactose'
											className='form-control'
											value={lactose}
											onChange={e => setLactose(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="sng"
											className='form-label'
										>S.N.G.</label>
										<input
											type='number'
											id='sng'
											name='sng'
											className='form-control'
											value={sng}
											onChange={e => setSng(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="st"
											className='form-label'
										>S.T.</label>
										<input
											type='number'
											id='st'
											name='st'
											className='form-control'
											value={st}
											onChange={e => setSt(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="water"
											className='form-label'
										>Agua</label>
										<input
											type='number'
											id='water'
											name='water'
											className='form-control'
											value={water}
											onChange={e => setWater(e.target.value)}
											required
										></input>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="acidity"
											className='form-label'
										>Acidez</label>
										<select
											id='acidity'
											name='acidity'
											className='form-select'
											defaultValue={acidity === null ? 'DEFAULT' : acidity}
											onChange={e => setAcidity(e.target.value)}
											required
										>
											<option value="DEFAULT" disabled>Seleccione una opción</option>
											<option value="0">Negativo</option>
											<option value="1">Positivo</option>
										</select>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="cmt"
											className='form-label'
										>CMT (Mastitis sublínica)</label>
										<select
											id='cmt'
											name='cmt'
											className='form-select'
											defaultValue={cmt === null ? 'DEFAULT' : cmt}
											onChange={e => setCmt(e.target.value)}
											required
										>
											<option value="DEFAULT" disabled>Seleccione una opción</option>
											<option value="0">Negativo</option>
											<option value="1">Positivo</option>
										</select>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="cryoscopy"
											className='form-label'
										>Crioscopia</label>
										<input
											type='number'
											id='cryoscopy'
											name='cryoscopy'
											className='form-control'
											value={cryoscopy}
											onChange={e => setCryoscopy(e.target.value)}
											required
										></input>
									</div>
									<div className="col-12 col-sm-6 col-md-3">
										<label
											htmlFor="ufc"
											className='form-label'
										>UFC</label>
										<input
											type='number'
											id='ufc'
											name='ufc'
											className='form-control'
											value={ufc}
											onChange={e => setUfc(e.target.value)}
											required
										></input>
									</div>
								</div>
								<div className='row'>
									<div className='col-12 col-sm-6'>
										<button className='btn btn-primary'>Guardar</button>
									</div>
									<div className='col-12 col-sm-6'>
										<Link to='/dashboard-admin/internal-quality' className='btn btn-secondary'>Regresar</Link>
									</div>
								</div>
							</form>
						</div>
					</div>
					:
					''
				}
			</div>
		</div>
	)
}

export default InternalQualityAdd
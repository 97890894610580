import { Navigate, Outlet } from "react-router-dom"
import Header from "../common/Header"
import useAuthTank from "../hooks/useAuthTank"

const ProtectedTankLayout = () => {
	const {auth, loading} = useAuthTank()
	if (loading) {
		return (
			<div className='d-flex justify-content-center'>
			  <div className='spinner-grow spinner-grow-xl text-danger' role='status'></div>
			</div>
		)
	}

	return (
		<div>
			{
				auth.oid
				?
				<div className="container-fluid">
					<div className="row">
						<Header></Header>
					</div>
					<div className="row justify-content-center">
						<Outlet></Outlet>
					</div>
				</div>
				:
				<Navigate to='/'></Navigate>
			}
		</div>
	)
}

export default ProtectedTankLayout
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Message from "../utils/Message"
import Alert from "../components/common/Alert"
import axios from "axios"
import useAuth from "../components/hooks/useAuth"

const Auth = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [alert, setAlert] = useState({})

	const navigate = useNavigate()
	const {setAuth} = useAuth()

	const handlerSubmit = async (e) => {
		try {
			e.preventDefault()
			if (!email || !password) {
				setAlert({
					msg: Message.ENTER_LOGIN,
					error: true
				})
				return
			}
			const {data} = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/user/login`,
				{
					email,
					password
				}
			)
			setAlert({})
			sessionStorage.setItem('token', data.tokenJwt)
			setAuth(data)
            navigate('/dashboard-admin')
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
	}

	return (
		<div className="auth col-12 col-sm-9 position-absolute top-50 start-50 translate-middle">
			<form
				className="form-auth"
				onSubmit={handlerSubmit}
			>
				<h1 className="h1-auth">Login administración</h1>
				<div className="form-outline mb-4">
				{alert.msg && <Alert alert={alert}></Alert>}
					<label
						className="label-auth form-label"
						htmlFor="user"
					>Usuario:</label>
					<input
						type="text"
						id="user"
						className="form-control"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
				</div>
				<div className="form-outline mb-4">
					<label
						className="label-auth form-label"
						htmlFor="password"
					>Contraseña:</label>
					<input
						type="password"
						id="password"
						className="form-control"
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
				</div>
				<button
					className="btn-auth btn btn-primary btn-block mb-4"
				>Ingresar</button>
				<Link className="link-auth">¿Olvidaste tu contraseña?</Link>
			</form>
		</div>
	)
}

export default Auth
import { HashRouter, Routes, Route } from 'react-router-dom'
import AuthLayout from './components/layouts/AuthLayout';
import AuthTankLayout from './components/layouts/AuthTankLayout';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import ProtectedTankLayout from './components/layouts/ProtectedTankLayout';
import Auth from './views/Auth';
import AuthTank from './views/AuthTank';
import Dashboard from './views/Dashboard';
import DashboardTank from './views/DashboardTank';
import Test from './views/Test';
import { AuthProviderTank } from './components/context/AuthProviderTank';
import { AuthProvider } from './components/context/AuthProvider';
import InternalQuality from './views/InternalQuality';
import InternalQualityAdd from './views/InternalQualityAdd';
import Administration from './views/Administration';
import AdministrationAdjustment from './views/AdministrationAdjustment';
import AdministrationChangeStatus from './views/AdministrationChangeStatus';
import ExternalQuality from './views/ExternalQuality';
import ExternalQualityAdd from './views/ExternalQualityAdd';
import Amount from './views/Amount';
import Movement from './views/Movement';
import Report from './views/Report';
import ReportAccountingArco from './views/ReportAccountingArco';
import ReportInfoDatalogger from './views/ReportInfoDatalogger';
import ReportRegistryAdmin from './views/ReportRegistryAdmin';
import AdministrationFinished from './views/AdministrationFinished';
import Configuration from './views/Configuration';
import UserAdmin from './views/UserAdmin';
import UserTank from './views/UserTank';
import UserTankAdd from './views/UserTankAdd';
import UserTankUpdate from './views/UserTankUpdate';
import Producer from './views/Producer';
import ProducerUpdate from './views/ProducerUpdate';
import ProducerAdd from './views/ProducerAdd';
import ReportInternalQuality from './views/ReportInternalQuality';
import ReportExternalQuality from './views/ReportExternalQuality';

function App() {
	return (
		<HashRouter>
			<AuthProviderTank>
				<AuthProvider>
					<Routes>
						{/* Login tanks */}
						<Route exact path='/' element={<AuthTankLayout></AuthTankLayout>}>
							<Route exact index element={<AuthTank></AuthTank>}></Route>
						</Route>

						{/* Login Admin */}
						<Route exact path='/login-admin/' element={<AuthLayout></AuthLayout>}>
							<Route exact index element={<Auth></Auth>}></Route>
						</Route>

						{/* Dashboard tanks */}
						<Route exact path='/dashboard-tank/' element={<ProtectedTankLayout></ProtectedTankLayout>}>
							<Route exact index element={<DashboardTank></DashboardTank>}></Route>
						</Route>

						{/* Dashboard Admin */}
						<Route exact path='/dashboard-admin/' element={<ProtectedLayout></ProtectedLayout>}>
							<Route exact index element={<Dashboard></Dashboard>}></Route>
							<Route exact path='test/' element={<Test></Test>}></Route>
							<Route exact path='internal-quality/' element={<InternalQuality></InternalQuality>}></Route>
							<Route exact path='internal-quality-add/:oid' element={<InternalQualityAdd></InternalQualityAdd>}></Route>
							<Route exact path='administration/' element={<Administration></Administration>}></Route>
							<Route exact path='administration-finished/' element={<AdministrationFinished></AdministrationFinished>}></Route>
							<Route exact path='adjustment/:oid' element={<AdministrationAdjustment></AdministrationAdjustment>}></Route>
							<Route exact path='change-status/:oid' element={<AdministrationChangeStatus></AdministrationChangeStatus>}></Route>
							<Route exact path='external-quality/' element={<ExternalQuality></ExternalQuality>}></Route>
							<Route exact path='external-quality-add/' element={<ExternalQualityAdd></ExternalQualityAdd>}></Route>
							<Route exact path='amount/' element={<Amount></Amount>}></Route>
							<Route exact path='movement/:oid' element={<Movement></Movement>}></Route>
							<Route exact path='report/' element={<Report></Report>}></Route>
							<Route exact path='report-arco/' element={<ReportAccountingArco></ReportAccountingArco>}></Route>
							<Route exact path='report-registry/' element={<ReportRegistryAdmin></ReportRegistryAdmin>}></Route>
							<Route exact path='report-info-datalogger/' element={<ReportInfoDatalogger></ReportInfoDatalogger>}></Route>
							<Route exact path='configuration/' element={<Configuration></Configuration>}></Route>
							<Route exact path='user-tank/' element={<UserTank></UserTank>}></Route>
							<Route exact path='user-tank-add/' element={<UserTankAdd></UserTankAdd>}></Route>
							<Route exact path='user-tank-update/:oid' element={<UserTankUpdate></UserTankUpdate>}></Route>
							<Route exact path='producer/' element={<Producer></Producer>}></Route>
							<Route exact path='producer-update/:oid' element={<ProducerUpdate></ProducerUpdate>}></Route>
							<Route exact path='producer-add/' element={<ProducerAdd></ProducerAdd>}></Route>
							<Route exact path='report-internal-quality/' element={<ReportInternalQuality></ReportInternalQuality>}></Route>
							<Route exact path='report-external-quality/' element={<ReportExternalQuality></ReportExternalQuality>}></Route>
						</Route>
					</Routes>
				</AuthProvider>
			</AuthProviderTank>
		</HashRouter>
	)
}

export default App;

import { useEffect, useState } from "react"
import Alert from "../components/common/Alert"
import { Link, useParams } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"

const ProducerUpdate = () => {
    const [alert, setAlert] = useState({})
	const [code, setCode] = useState('')
	const [nit, setNit] = useState('')
	const [name, setName] = useState('')
	const [recordStatus, setRecordStatus] = useState('')
	const [usertankname, setUsertankname] = useState('')
	const [userTank, setUserTank] = useState('')
	const [tanks, setTanks] = useState([])

	const {oid} = useParams()

	const token = sessionStorage.getItem('token')
	const config = AxiosHeader(token)

    useEffect(() => {
		const getProducer = async () => {
			try {
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/producer/get-by-oid/${oid}`,
					config
				)
				setCode(data[0].code)
				setNit(data[0].nit)
				setName(data[0].name)
				setRecordStatus(data[0].record_status)
				setUsertankname(data[0].usertankname)
				setUserTank(data[0].usertank)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getProducer()

		const getUserTank = async () => {
			try {
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/user-tank/findall`,
					config
				)
				setTanks(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getUserTank()
    }, [alert])

    const handleSubmit = async (e) => {
        try {
			e.preventDefault()
			setAlert({})
			const {data} = await axios.put(
				`${process.env.REACT_APP_URL_SERVER}/producer/update/${oid}`,
				{
					code,
					nit,
					name,
					recordStatus,
					userTank
				},
				config
			)
			setAlert({
				msg: data.msg,
				error: false
			})
			setTimeout(() => {
				setAlert({})
			}, 5000);
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
    }

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Actualizar productor</h1>

				<br />

					<div className="text-center">
						{alert.msg && <Alert alert={alert}></Alert>}
					</div>

					<form onSubmit={handleSubmit}>
						<div className="container text-center mt-2">
							<div className="row mb-1">
								<div className="col-12 col-md-4">
									<label
										htmlFor="code"
										className="form-label"
									>Código</label>
									<input
										type="text"
										id="code"
										name="code"
										className="form-control"
										value={code}
										onChange={e => setCode(e.target.value)}
										required
									></input>
								</div>
								<div className="col-12 col-md-4">
									<label
										htmlFor="nit"
										className="form-label"
									>NIT</label>
									<input
										type="text"
										id="nit"
										name="nit"
										className="form-control"
										value={nit}
										onChange={e => setNit(e.target.value)}
										required
									></input>
								</div>
								<div className="col-12 col-md-4">
									<label
										htmlFor="name"
										className="form-label"
									>Nombre</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										value={name}
										onChange={e => setName(e.target.value)}
										required
									></input>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col-12 col-md-6">
									<label
										htmlFor="status"
										className="form-label"
									>Estado</label>
									<select
										id="status"
										name="status"
										className="form-select"
										onChange={e => setRecordStatus(e.target.value)}
									>
										{
											recordStatus === 1
											?
											<>
												<option value="1">Activo</option>
												<option value="0">Inactivo</option>
											</>
											:
											<>
												<option value="0">Inactivo</option>
												<option value="1">Activo</option>
											</>
										}
									</select>
								</div>
								<div className="col-12 col-md-6">
									<label
										htmlFor="tank"
										className="form-label"
									>Tanque</label>
									<select
										id="tank"
										name="tank"
										className="form-select"
										onChange={e => setUserTank(e.target.value)}
									>
										<option value={userTank}>{usertankname}</option>
										{
											tanks.map((tank, index) => (
												<option key={index} value={tank.oid}>{tank.name}</option>
											))
										}
									</select>
								</div>
							</div>
						</div>
						<br />
						<div className="container text-center mb-5">
							<div className="row mb-3">
								<div className="col-12 col-sm-6">
									<button className='btn btn-primary'>Guardar</button>
								</div>
								<div className='col-12 col-sm-6'>
									<Link to='/dashboard-admin/producer' className='btn btn-secondary'>Regresar</Link>
								</div>
							</div>
						</div>
					</form>
			</div>
		</div>
	)
}

export default ProducerUpdate
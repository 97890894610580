import { Link } from "react-router-dom"

const Report = () => {

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Reportes</h1>

				<div className="container text-center mt-2">
					<div className="row mb-1">
						<div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Contabilidad Arco</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/report-arco' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card border-primary mb-3">
								<div className="card-header">Registro de leche</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/report-registry' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb-1">
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Calidad interna</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/report-internal-quality' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Calidad externa</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/report-external-quality' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Datalogger</div>
								<div className="card-body">
									<p className="card-text">
										<Link to='/dashboard-admin/report-info-datalogger' className='btn btn-primary'>Ingresar</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Report
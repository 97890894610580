import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"

const UserTank = () => {
	const [users, setUsers] = useState([])

	useEffect(() => {
		const getUsers = async () => {
			const token = sessionStorage.getItem('token')
			const config = AxiosHeader(token)
			const {data} = await axios.get(
				`${process.env.REACT_APP_URL_SERVER}/user-tank/findall`,
				config
			)
			setUsers(data)
		}
		getUsers()
	}, [])

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Usuarios tanques</h1>

				<div className="container text-center mb-5">
					<div className="row mb-3">
						<div className="col-12">
							<Link to='/dashboard-admin/user-tank-add' className='btn btn-primary'>Crear registro</Link>
						</div>
					</div>
				</div>

				<hr />

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Código</th>
							<th scope="col">Nombre</th>
							<th scope="col">Estado</th>
							<th scope="col">Editar</th>
						</tr>
					</thead>
					<tbody>
						{
							users.map((user, index) => (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>{user.code}</td>
									<td>{user.name}</td>
									<td>
										{user.recordStatus === 1 ? 'Activo' : 'Inactivo'}
									</td>
									<td><Link to={`/dashboard-admin/user-tank-update/${user.oid}`} className='btn btn-primary'></Link></td>
								</tr>
							))
						}
					</tbody>
				</table>
			</form>
		</div>
	)
}

export default UserTank
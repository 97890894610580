import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import Alert from "../components/common/Alert"

const Movement = () => {
    const [tanks, setTanks] = useState({})
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState('')
    const [date, setDate] = useState('')
    const [amount, setAmount] = useState(0)
    const [alert, setAlert] = useState({})
    const [outPuts, setOutPuts] = useState([])

    const {oid} = useParams()

    useEffect(() => {
        const token = sessionStorage.getItem('token')
        const config = AxiosHeader(token)
        const getTank = async () => {
            const {data} = await axios.get(
                `${process.env.REACT_APP_URL_SERVER}/user-tank/find-oid/${oid}`,
                config
            )
            setTanks(data)
        }
        getTank()

        const getCustomer = async () => {
            const {data} = await axios.get(
                `${process.env.REACT_APP_URL_SERVER}/customer/findall`,
                config
            )
            setCustomers(data)
        }
        getCustomer()

        const getOutput = async () => {
            const {data} = await axios.get(
                `${process.env.REACT_APP_URL_SERVER}/output/findall/${oid}`,
                config
            )
            setOutPuts(data)
        }
        getOutput()
    }, [alert])

    const handlerSubmit = async (e) => {
        try {
            e.preventDefault()
            setAlert({})
            const currentAmount = tanks.amount
            const token = sessionStorage.getItem('token')
            const config = AxiosHeader(token)
            const {data} = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/output/add`,
                {
                    oid,
                    customer,
                    date,
                    amount,
                    currentAmount
                },
                config
            )
            setAlert({
                msg: data.msg,
                error: false
            })
            setTimeout(() => {
                setAlert({})
            }, 5000);
        } catch (error) {
            setAlert({
                msg: error.response.data.msg,
                error: true
            })
        }
    }

    return (
        <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
            <div className="form-dashboard">
                <h1 className="h1-dashboard">Movimiento</h1>

                {alert.msg && <Alert alert={alert}></Alert>}

                <div className="container text-center mb-5">
                    <form onSubmit={handlerSubmit}>
                        <div className="row mb-3">
                            <div className="col-12 col-md-7">
                                <label
                                    htmlFor="usertank"
                                    className="form-label"
                                >Tanque</label>
                                <input
                                    type="text"
                                    id="usertank"
                                    name="usertank"
                                    className="form-control"
                                    value={tanks.name}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-12 col-md-5">
                                <label
                                    htmlFor="currentamount"
                                    className="form-label"
                                >Cantidad actual</label>
                                <input
                                    type="text"
                                    id="currentamount"
                                    name="currentamount"
                                    className="form-control"
                                    value={tanks.amount}
                                    disabled
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-7">
                                <label
                                    htmlFor="customer"
                                    className="form-label"
                                >Cliente</label>
                                <select
                                    id="customer"
                                    name="customer"
                                    className="form-select"
                                    defaultValue='DEFAULT'
                                    onChange={e => setCustomer(e.target.value)}
                                    required
                                >
                                    <option value="DEFAULT" disabled>Seleccione una opción</option>
                                    {
                                        customers.map((customer, index) => (
                                            <option key={index} value={customer.oid}>{customer.name}</option>
                                        ))
                                    }

                                </select>
                            </div>
                            <div className="col-12 col-md-3">
                                <label
                                    htmlFor="date"
                                    className="form-label"
                                >Fecha</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    className="form-control"
                                    value={date}
                                    onChange={e => setDate(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-2">
                                <label
                                    htmlFor="amount"
                                    className="form-label"
                                >Cantidad</label>
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    className="form-control"
                                    value={amount}
                                    onChange={e => setAmount(e.target.value)}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <button className='btn btn-primary'>Guardar</button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Link to='/dashboard-admin/amount' className='btn btn-secondary'>Regresar</Link>
                            </div>
                        </div>
                    </form>
                </div>

                <hr></hr>

                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Tanque</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            outPuts.map((outPut, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{outPut.usertank}</td>
                                    <td>{outPut.customer}</td>
                                    <td>{outPut.date}</td>
                                    <td>{outPut.amount}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Movement
import { Link, NavLink } from "react-router-dom"

const Aside = () => {
    return (
        <div className="col-3 col-sm-3 col-md-3 col-xl-2 ps-0">
            <div className="px-sm-2 px-0 bg-dark min-vh-100">
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2">
                    <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-5 d-none d-sm-inline">Menu</span>
                    </a>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

                        <li className='nav-item'>
                            <Link to='/dashboard-admin' className='nav-link px-0 align-middle'>
                                <i className="bi bi-alarm"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Inicio</span>
                            </Link>
                        </li>

                        <li>
                            <Link to='internal-quality' className='nav-link px-0 align-middle'>
                                <i className="bi bi-building-add"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Calidad interna</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='external-quality' className='nav-link px-0 align-middle'>
                                <i className="bi bi-file-earmark-check"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Calidad externa</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='administration' className='nav-link px-0 align-middle'>
                                <i className="bi bi-globe"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Administración</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='amount' className='nav-link px-0 align-middle'>
                                <i className="bi bi-cart2"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Movimientos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='report' className='nav-link px-0 align-middle'>
                                <i className="bi bi-journals"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Reportes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='configuration' className='nav-link px-0 align-middle'>
                                <i className="bi bi-hammer"></i>
                                <span className="ms-1 d-none d-sm-inline text-white">Configuración</span>
                            </Link>
                        </li>
                    </ul>
                    <hr></hr>
                    <div className="dropdown pb-4">
                        {/* <a href="/#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-person-circle"></i>
                            <span className="d-none d-sm-inline mx-1">Usuario</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                            <li><a className="dropdown-item" href="/#">Configuración</a></li>
                            <li><a className="dropdown-item" href="/#">Perfil</a></li>
                            <li>
                                <hr className="dropdown-divider"></hr>
                            </li>
                            <li><a className="dropdown-item" href="/#">Salir</a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aside
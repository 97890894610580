import axios from "axios";
import { useState, useEffect, createContext } from "react";
import AxiosHeader from "../../utils/AxiosHeader";

const AuthContextTank = createContext()

const AuthProviderTank = ({children}) => {
    const [auth, setAuth] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const authUser = async () => {
            try {
                const token = sessionStorage.getItem('token-tank')
                if (!token) {
                    setLoading(false)
                    return
                }
                const config = AxiosHeader(token)
                const {data} = await axios.get(
                    `${process.env.REACT_APP_URL_SERVER}/user-tank/profile`,
                    config
                )
                setAuth(data)
            } catch (error) {
                setAuth({})
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        authUser()
    }, [])

    return(
        <AuthContextTank.Provider
            value={{
                auth,
                setAuth,
                loading
            }}
        >
            {children}
        </AuthContextTank.Provider>
    )
}

export {
    AuthProviderTank
}

export default AuthContextTank
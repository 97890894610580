import React, { useEffect, useState } from 'react'
import AxiosHeader from '../utils/AxiosHeader'
import axios from 'axios'
import Alert from '../components/common/Alert'
import { Link } from 'react-router-dom'

const InternalQuality = () => {
	const [alert, setAlert] = useState({})
	const [registries, setRegistries] = useState([])
	const [access, setAccess] = useState(false)
	const [finish, setFinish] = useState('')
	const [discard, setDiscard] = useState('')

	useEffect(() => {
		const getRegistries = async() => {
			try {
				const token = sessionStorage.getItem('token')
				const config = AxiosHeader(token)
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/registry/findall-quality`,
					config
				)
				setAccess(true)
				setRegistries(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getRegistries()
	}, [alert])

	const finishClick = async (e) => {
		e.preventDefault()
		setAlert({})
		const token = sessionStorage.getItem('token')
		const config = AxiosHeader(token)
		const {data} = await axios.post(
			`${process.env.REACT_APP_URL_SERVER}/registry/finish`,
			{
				finish
			},
			config
		)
		setAlert({
			msg: data.msg,
			error: false
		})
		setTimeout(() => {
			setAlert({})
		}, 5000);
	}

	const discardClick = async (e) => {
		e.preventDefault()
		setAlert({})
		const token = sessionStorage.getItem('token')
		const config = AxiosHeader(token)
		const {data} = await axios.post(
			`${process.env.REACT_APP_URL_SERVER}/registry/discard`,
			{
				discard
			},
			config
		)
		setAlert({
			msg: data.msg,
			error: false
		})
		setTimeout(() => {
			setAlert({})
		}, 5000);
	}

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Calidad composicional interna</h1>

				{alert.msg && <Alert alert={alert}></Alert>}

				{
					access
					?
						registries.length > 0
						?
						<table className="table table-striped table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Productor</th>
									<th scope="col">Tanque</th>
									<th scope="col">Fecha</th>
									<th scope="col">Hora</th>
									<th scope="col">Cantidad</th>
									<th scope="col">Agregar</th>
									<th scope="col">Finalizar</th>
									<th scope="col">Descartar</th>
								</tr>
							</thead>
							<tbody>
								{registries.map((registry, index) => (
									<tr key={index}>
										<th scope="row">{index + 1}</th>
										<td>{registry.producer}</td>
										<td>{registry.usertank}</td>
										<td>{registry.date}</td>
										<td>{registry.hour === 0 ? 'AM' : 'PM'}</td>
										<td>{registry.amount}</td>

										{
											registry.has_internal_quality === 0
											?
											<td><Link to={`/dashboard-admin/internal-quality-add/${registry.oid}`} className='btn btn-secondary'></Link></td>
											:
											<td><Link to={`/dashboard-admin/internal-quality-add/${registry.oid}`} className='btn btn-primary'></Link></td>
										}

										{
											registry.has_internal_quality === 0
											?
											<td></td>
											:
											<div>
												<td><button type='button' className='btn btn-success' data-bs-toggle="modal" data-bs-target="#finishModal" onClick={e => setFinish(registry.oid)}></button></td>

												<div className="modal fade" id="finishModal" tabIndex="-1" aria-labelledby="finishModalLabel" aria-hidden="true">
													<div className="modal-dialog">
														<div className="modal-content">
														<div className="modal-header">
															<h1 className="modal-title fs-5" id="finishModalLabel">Finalizar registro</h1>
															<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
														</div>
														<div className="modal-body">
															Desea finalizar la edición del registro?
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
															<button type="button" className="btn btn-primary" onClick={finishClick} data-bs-dismiss="modal">Finalizar</button>
														</div>
														</div>
													</div>
												</div>
											</div>
										}

										<td><button type='button' className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#discardModal" onClick={e => setDiscard(registry.oid)}></button></td>

										<div className="modal fade" id="discardModal" tabIndex="-1" aria-labelledby="discardModalLabel" aria-hidden="true">
											<div className="modal-dialog">
												<div className="modal-content">
												<div className="modal-header">
													<h1 className="modal-title fs-5" id="discardModalLabel">Descartar registro</h1>
													<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
												</div>
												<div className="modal-body">
													Desea descartar el registro?
												</div>
												<div className="modal-footer">
													<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
													<button type="button" className="btn btn-primary" onClick={discardClick} data-bs-dismiss="modal">Descartar</button>
												</div>
												</div>
											</div>
										</div>

									</tr>
								))}
							</tbody>
						</table>
						:
						<h4>¡No hay registros para mostrar!</h4>
					:
					<h4>¡No tiene permisos para ingresar a este módulo!</h4>
				}
			</form>
		</div>
	)
}

export default InternalQuality
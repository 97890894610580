import { createContext, useEffect, useState } from "react";
import AxiosHeader from "../../utils/AxiosHeader";
import axios from "axios";

const AuthContext = createContext()

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const authUser = async () => {
            try {
                const token = sessionStorage.getItem('token')
                if (!token) {
                    setLoading(false)
                    return
                }
                const config = AxiosHeader(token)
                const {data} = await axios.get(
                    `${process.env.REACT_APP_URL_SERVER}/user/profile`,
                    config
                )
                setAuth(data)
            } catch (error) {
                setAuth({})
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        authUser()
    }, [])

    return(
        <AuthContext.Provider
            value={{
                auth,
                setAuth,
                loading
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {
    AuthProvider
}

export default AuthContext
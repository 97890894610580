import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"

const Producer = () => {
	const [alert, setAlert] = useState({})
	const [producers, setProducers] = useState([])

	useEffect(() => {
		const getProducer = async () => {
			try {
				const token = sessionStorage.getItem('token')
				const config = AxiosHeader(token)
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/producer/find-all`,
					config
				)
				setProducers(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getProducer()
	}, [])

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Productores</h1>

				<div className="container text-center mb-5">
					<div className="row mb-3">
						<div className="col-12">
							<Link to='/dashboard-admin/producer-add' className='btn btn-primary'>Crear registro</Link>
						</div>
					</div>
				</div>

				<hr />

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Código</th>
							<th scope="col">NIT</th>
							<th scope="col">Nombre</th>
							<th scope="col">Tanque</th>
							<th scope="col">Estado</th>
							<th scope="col">Editar</th>
						</tr>
					</thead>
					<tbody>
						{
							producers.map((producer, index) => (
							<tr key={index}>
								<th scope="row">{index + 1}</th>
								<td>{producer.code}</td>
								<td>{producer.nit}</td>
								<td>{producer.name}</td>
								<td>{producer.name_tank}</td>
								<td>{producer.record_status === 1 ? 'Activo' : 'Inactivo'}</td>
								<td><Link to={`/dashboard-admin/producer-update/${producer.oid}`} className='btn btn-primary'></Link></td>
							</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Producer
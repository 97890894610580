const Dashboard = () => {
	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Menú principal</h1>

				<div className="main-image"></div>
			</form>
		</div>
	)
}

export default Dashboard
import { Navigate, Outlet } from "react-router-dom"
import Header from "../common/Header"
import Aside from "../common/Aside"
import useAuth from "../hooks/useAuth"

const ProtectedLayout = () => {
	const {auth, loading} = useAuth()
	if (loading) {
		return (
			<div className='d-flex justify-content-center'>
			  <div className='spinner-grow spinner-grow-xl text-danger' role='status'></div>
			</div>
		)
	}

	return (
		<div>
			{
				auth.oid
				?
				<div className="container-fluid">
					<div className="row">
						<Header></Header>
					</div>
					<div className="row">
						<Aside></Aside>
						<Outlet></Outlet>
					</div>
				</div>
				:
				<Navigate to='/login-admin'></Navigate>
			}
		</div>
	)
}

export default ProtectedLayout
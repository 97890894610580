const Test = () => {

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Test</h1>


				<div className="container text-center mt-2">
					<div className="row mb-1">
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb-1">
						<div className="col-12 col-md-3">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="card border-primary mb-3">
								<div className="card-header">Header</div>
								<div className="card-body">
									<p className="card-text"><button type="button" className="btn btn-primary">Primary</button></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Test
import { useState } from "react"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import { Link } from "react-router-dom"

const AdministrationFinished = () => {
	const [registries, setregistries] = useState({})
    const [initialdate, setInitialdate] = useState('')
    const [endDate, setEndDate] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = sessionStorage.getItem('token')
        const config = AxiosHeader(token)
        const {data} = await axios.post(
            `${process.env.REACT_APP_URL_SERVER}/registry/findall-admin-finished`,
            {
                initialdate,
                endDate
            },
            config
        )
        setregistries(data)
    }

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Registros finalizados</h1>

                <form onSubmit={handleSubmit}>
                    <div className="container text-center mb-5">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="initialdate"
                                >Fecha inicio</label>
                                <input
                                    type="date"
                                    id="initialdate"
                                    name="initialdate"
                                    className="form-control"
                                    value={initialdate}
                                    onChange={e => setInitialdate(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    className="form-label"
                                    htmlFor="enddate"
                                >Fecha fin</label>
                                <input
                                    type="date"
                                    id="enddate"
                                    name="enddate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <button className="btn btn-primary">Generar</button>
                            </div>
                        </div>
                    </div>
                </form>

				{/* {alert.msg && <Alert alert={alert}></Alert>} */}

				{
                    registries.length > 0
                    ?
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Productor</th>
                                <th scope="col">Tanque</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Tiene calidad interna</th>
                                <th scope="col">Cambiar estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                registries.map((registry, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{registry.producer}</td>
                                        <td>{registry.usertank}</td>
                                        <td>{registry.date}</td>
                                        <td>{registry.hour === 0 ? 'AM' : 'PM'}</td>
                                        <td>{registry.amount}</td>
                                        {registry.has_internal_quality === 0 && <td>No</td>}
                                        {registry.has_internal_quality === 1 && <td>Si</td>}
                                        {registry.has_internal_quality === 2 && <td>Descartado</td>}
                                        {registry.registry_status === 0 && <td>Registrado</td>}
                                        {registry.registry_status === 1 && <td>Registrado</td>}
                                        {registry.registry_status === 2 && <td>Registrado</td>}
                                        {registry.registry_status === 3 && <td>Cerrado agregar</td>}
                                        {registry.registry_status === 4 && <td>Cerrado calidad interna</td>}
                                        <td><Link to={`/dashboard-admin/change-status/${registry.oid}`}  className='btn btn-success'></Link></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <h4>¡Seleccione un rango de fechas!</h4>
				}
			</div>
		</div>
		)
	}

export default AdministrationFinished
const moment = require('moment-timezone')

const CurrentDate = () => {
    return moment().utc('-05:00')
}

const CurrentDateOnlyDate = () => {
    let date = moment().utc('-05:00')
    date = moment(date).format('YYYY-MM-DD');
    return date
}

const FormatDateTime = (date) => {
    const newDate = moment(date, 'DD/MM/YYYY HH:mm:ss')
    return newDate
}

const FormatDateMDY = (date) => {
    const newDate = moment(date).format('MM/DD/YYYY')
    return newDate
}

export {
    CurrentDate,
    CurrentDateOnlyDate,
    FormatDateTime,
    FormatDateMDY
}
import { useEffect, useState } from "react"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"
import Alert from "../components/common/Alert"
import { Link } from "react-router-dom"

const Administration = () => {
	const [alert, setAlert] = useState({})
	const [access, setAccess] = useState(false)
	const [registries, setregistries] = useState({})
	const [search, setSearch] = useState('')

	useEffect(() => {
		const getAllRegistries = async () => {
			try {
				const token = sessionStorage.getItem('token')
			const config = AxiosHeader(token)
			const {data} = await axios.get(
				`${process.env.REACT_APP_URL_SERVER}/registry/findall-admin`,
				config
			)
			setAccess(true)
			setregistries(data)
			} catch (error) {
				setAlert({
					msg: error.response.data.msg,
					error: true
				})
			}
		}
		getAllRegistries()
	}, [])

	const searcher = (e) => {
        setSearch(e.target.value)
    }

	let result = []
    if (!search) {
        result = registries
    } else {
        result = registries.filter((datt) =>
            datt.usertank.toLowerCase().includes(search.toLocaleLowerCase())
        )
    }

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<form className="form-dashboard">
				<h1 className="h1-dashboard">Administración</h1>

				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-6 text-center">
							<input
								type="text"
								id="search"
								name="search"
								className="form-control"
								placeholder="Buscar tanque"
								value={search}
								onChange={searcher}
							></input>
						</div>
						<div className="col-12 col-sm-6 text-center">
							<Link to={`/dashboard-admin/administration-finished`} className='bt-link btn btn-secondary'>Registros finalizados</Link>
						</div>
					</div>
				</div>

				{alert.msg && <Alert alert={alert}></Alert>}

				{
					access
					?
						registries.length > 0
						?
						<table className="table table-striped table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Productor</th>
									<th scope="col">Tanque</th>
									<th scope="col">Fecha</th>
									<th scope="col">Hora</th>
									<th scope="col">Cantidad</th>
									<th scope="col">Tiene calidad interna</th>
									<th scope="col">Estado</th>
									<th scope="col">Ajuste</th>
									<th scope="col">Cambiar estado</th>
								</tr>
							</thead>
							<tbody>
								{
									result.map((registry, index) => (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>{registry.producer}</td>
											<td>{registry.usertank}</td>
											<td>{registry.date}</td>
											<td>{registry.hour === 0 ? 'AM' : 'PM'}</td>
											<td>{registry.amount}</td>
											{registry.has_internal_quality === 0 && <td>No</td>}
											{registry.has_internal_quality === 1 && <td>Si</td>}
											{registry.has_internal_quality === 2 && <td>Descartado</td>}
											{registry.registry_status === 0 && <td>Registrado</td>}
											{registry.registry_status === 1 && <td>Registrado</td>}
											{registry.registry_status === 2 && <td>Registrado</td>}
											{registry.registry_status === 3 && <td>Cerrado agregar</td>}
											{registry.registry_status === 4 && <td>Cerrado calidad interna</td>}
											{registry.registry_status === 5 && <td>Finalizado</td>}
											<td><Link to={`/dashboard-admin/adjustment/${registry.oid}`} className='btn btn-primary'></Link></td>
											<td><Link to={`/dashboard-admin/change-status/${registry.oid}`}  className='btn btn-success'></Link></td>
										</tr>
									))
								}
							</tbody>
						</table>
						:
						<h4>¡No hay registros para mostrar!</h4>
					:
					''
				}
			</form>
		</div>
		)
	}

export default Administration
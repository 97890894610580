import { useEffect, useState } from "react"
import axios from "axios";
import AxiosHeader from "../utils/AxiosHeader"
import Alert from "../components/common/Alert";
import { Link } from "react-router-dom";

const ExternalQualityAdd = () => {
	const [usersTank, setUsersTank] = useState([])
	const [customers, setCustomers] = useState([])
	const [alert, setAlert] = useState({})
	const [userTank, setUserTank] = useState('')
	const [customer, setCustomer] = useState('')
	const [liquidationDate, setLiquidationDate] = useState('')
	const [liquidatedFrom, setLiquidatedFrom] = useState('')
	const [liquidatedUntil, setLiquidatedUntil] = useState('')
	const [protein1, setProtein1] = useState(0)
	const [protein2, setProtein2] = useState(0)
	const [protein3, setProtein3] = useState(0)
	const [proteinAverage, setProteinAverage] = useState(0)
	const [grease1, setGrease1] = useState(0)
	const [grease2, setGrease2] = useState(0)
	const [grease3, setGrease3] = useState(0)
	const [greaseAverage, setGreaseAverage] = useState(0)
	const [solids1, setSolids1] = useState(0)
	const [solids2, setSolids2] = useState(0)
	const [solids3, setSolids3] = useState(0)
	const [solidsAverage, setSolidsAverage] = useState(0)
	const [mun1, setMun1] = useState(0)
	const [mun2, setMun2] = useState(0)
	const [mun3, setMun3] = useState(0)
	const [munAverage, setMunAverage] = useState(0)
	const [rcs1, setRcs1] = useState(0)
	const [rcs2, setRcs2] = useState(0)
	const [rcs3, setRcs3] = useState(0)
	const [rcsAverage, setRcsAverage] = useState(0)
	const [density1, setDensity1] = useState(0)
	const [density2, setDensity2] = useState(0)
	const [density3, setDensity3] = useState(0)
	const [densityAverage, setDensityAverage] = useState(0)
	const [ufc1, setUfc1] = useState(0)
	const [ufc2, setUfc2] = useState(0)
	const [ufc3, setUfc3] = useState(0)
	const [ufcAverage, setUfcAverage] = useState(0)
	const [hygienic1, setHygienic1] = useState(0)
	const [hygienic2, setHygienic2] = useState(0)
	const [hygienic3, setHygienic3] = useState(0)
	const [hygienicAverage, setHygienicAverage] = useState(0)

	useEffect(() => {
		const token = sessionStorage.getItem('token')
		const config = AxiosHeader(token)
		const getUserTank = async () => {
			const {data} = await axios.get(
				`${process.env.REACT_APP_URL_SERVER}/user-tank/findall`,
				config
			)
			setUsersTank(data)
		}
		getUserTank()
		const getCustomer = async () => {
			const {data} = await axios.get(
				`${process.env.REACT_APP_URL_SERVER}/customer/findall`,
				config
			)
			setCustomers(data)
		}
		getCustomer()
	}, [])

	const handleSubmit = async (e) => {
		try {
			e.preventDefault()
			const token = sessionStorage.getItem('token')
			const config = AxiosHeader(token)
			const {data} = await axios.post(
				`${process.env.REACT_APP_URL_SERVER}/external-quality/add`,
				{
					userTank,
					customer,
					liquidationDate,
					liquidatedFrom,
					liquidatedUntil,
					protein1,
					protein2,
					protein3,
					proteinAverage,
					grease1,
					grease2,
					grease3,
					greaseAverage,
					solids1,
					solids2,
					solids3,
					solidsAverage,
					mun1,
					mun2,
					mun3,
					munAverage,
					rcs1,
					rcs2,
					rcs3,
					rcsAverage,
					density1,
					density2,
					density3,
					densityAverage,
					ufc1,
					ufc2,
					ufc3,
					ufcAverage,
					hygienic1,
					hygienic2,
					hygienic3,
					hygienicAverage
				},
				config
			)
			setAlert({
				msg: data.msg,
				error: false
			})
			setTimeout(() => {
				setAlert({})
			}, 3000);
		} catch (error) {
			setAlert({
				msg: error.response.data.msg,
				error: true
			})
		}
	}

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Agregar calidad externa</h1>

				<div className="text-center">
					{alert.msg && <Alert alert={alert}></Alert>}
				</div>

				<form onSubmit={handleSubmit}>
					<div className="container text-center mt-2">
						<div className="row mb-1">
							<div className="col-12 col-md-6">
								<label
									htmlFor="tank"
									className="form-label"
								>Tanque</label>
								<select
									id='tank'
									name='tank'
									className='form-select'
									defaultValue='DEFAULT'
									onChange={e => setUserTank(e.target.value)}
									required
								>
									<option value="DEFAULT" disabled>Seleccione una opción</option>
									{usersTank.map((tank, index) => (
										<option  key={index} value={tank.oid}>{tank.name}</option>
									))}
								</select>
							</div>
							<div className="col-12 col-md-6">
								<label
									htmlFor="customer"
									className="form-label"
								>Cliente</label>
								<select
									id='customer'
									name='customer'
									className='form-select'
									defaultValue='DEFAULT'
									onChange={e => setCustomer(e.target.value)}
									required
								>
									<option value="DEFAULT" disabled>Seleccione una opción</option>
									{customers.map((customer, index) => (
										<option  key={index} value={customer.oid}>{customer.name}</option>
									))}
								</select>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-12 col-sm-6 col-md-4">
								<label
									htmlFor="liquidation_date"
									className="form-label"
								>Fecha liquidación</label>
								<input
									type="date"
									id="liquidation_date"
									name="liquidation_date"
									className='form-control'
									onChange={e => setLiquidationDate(e.target.value)}
									required
								></input>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<label
									htmlFor="liquidated_from"
									className="form-label"
								>Desde</label>
								<input
									type="date"
									id="liquidated_from"
									name="liquidated_from"
									className='form-control'
									onChange={e => setLiquidatedFrom(e.target.value)}
									required
								></input>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<label
									htmlFor="liquidated_until"
									className="form-label"
								>Hasta</label>
								<input
									type="date"
									id="liquidated_until"
									name="liquidated_until"
									className='form-control'
									onChange={e => setLiquidatedUntil(e.target.value)}
									required
								></input>
							</div>
						</div>
					</div>
					<hr />
					<table className="table table-light table-hover table-bordered">
						<thead>
							<tr>
								<th></th>
								<th scope="col" colSpan="4" className="text-center">Resultado promedio últimos 3 ánalisis</th>
							</tr>
							<tr>
								<th scope="col" className="text-center">Calidad</th>
								<th scope="col" className="text-center">1</th>
								<th scope="col" className="text-center">2</th>
								<th scope="col" className="text-center">3</th>
								<th scope="col" className="text-center">Promedio</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">
									<label
										htmlFor="protein_1"
										className="form-label"
									>Proteína %</label>
								</th>
								<td>
									<input
										type="number"
										id="protein_1"
										name="protein_1"
										className="form-control external-text"
										onChange={e => setProtein1(e.target.value)}
										value={protein1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="protein_2"
										name="protein_2"
										className="form-control external-text"
										onChange={e => setProtein2(e.target.value)}
										value={protein2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="protein_3"
										name="protein_3"
										className="form-control external-text"
										onChange={e => setProtein3(e.target.value)}
										value={protein3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="protein_average"
										name="protein_average"
										className="form-control external-text"
										onChange={e => setProteinAverage(e.target.value)}
										value={proteinAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="grease_1"
										className="form-label"
									>Grasa %</label>
								</th>
								<td>
									<input
										type="number"
										id="grease_1"
										name="grease_1"
										className="form-control external-text"
										onChange={e => setGrease1(e.target.value)}
										value={grease1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="grease_2"
										name="grease_2"
										className="form-control external-text"
										onChange={e => setGrease2(e.target.value)}
										value={grease2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="grease_3"
										name="grease_3"
										className="form-control external-text"
										onChange={e => setGrease3(e.target.value)}
										value={grease3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="grease_average"
										name="grease_average"
										className="form-control external-text"
										onChange={e => setGreaseAverage(e.target.value)}
										value={greaseAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="solids_1"
										className="form-label"
									>Sólidos totales %</label>
								</th>
								<td>
									<input
										type="number"
										id="solids_1"
										name="solids_1"
										className="form-control external-text"
										onChange={e => setSolids1(e.target.value)}
										value={solids1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="solids_2"
										name="solids_2"
										className="form-control external-text"
										onChange={e => setSolids2(e.target.value)}
										value={solids2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="solids_3"
										name="solids_3"
										className="form-control external-text"
										onChange={e => setSolids3(e.target.value)}
										value={solids3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="solids_average"
										name="solids_average"
										className="form-control external-text"
										onChange={e => setSolidsAverage(e.target.value)}
										value={solidsAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="mun_1"
										className="form-label"
									>MUN (mg / dl)</label>
								</th>
								<td>
									<input
										type="number"
										id="mun_1"
										name="mun_1"
										className="form-control external-text"
										onChange={e => setMun1(e.target.value)}
										value={mun1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="mun_2"
										name="mun_2"
										className="form-control external-text"
										onChange={e => setMun2(e.target.value)}
										value={mun2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="mun_3"
										name="mun_3"
										className="form-control external-text"
										onChange={e => setMun3(e.target.value)}
										value={mun3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="mun_average"
										name="mun_average"
										className="form-control external-text"
										onChange={e => setMunAverage(e.target.value)}
										value={munAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="rcs_1"
										className="form-label"
									>RCS (1000 / ml)</label>
								</th>
								<td>
									<input
										type="number"
										id="rcs_1"
										name="rcs_1"
										className="form-control external-text"
										onChange={e => setRcs1(e.target.value)}
										value={rcs1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="rcs_2"
										name="rcs_2"
										className="form-control external-text"
										onChange={e => setRcs2(e.target.value)}
										value={rcs2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="rcs_3"
										name="rcs_3"
										className="form-control external-text"
										onChange={e => setRcs3(e.target.value)}
										value={rcs3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="rcs_average"
										name="rcs_average"
										className="form-control external-text"
										onChange={e => setRcsAverage(e.target.value)}
										value={rcsAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="density_1"
										className="form-label"
									>Densidad</label>
								</th>
								<td>
									<input
										type="number"
										id="density_1"
										name="density_1"
										className="form-control external-text"
										onChange={e => setDensity1(e.target.value)}
										value={density1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="density_2"
										name="density_2"
										className="form-control external-text"
										onChange={e => setDensity2(e.target.value)}
										value={density2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="density_3"
										name="density_3"
										className="form-control external-text"
										onChange={e => setDensity3(e.target.value)}
										value={density3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="density_average"
										name="density_average"
										className="form-control external-text"
										onChange={e => setDensityAverage(e.target.value)}
										value={densityAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="ufc_1"
										className="form-label"
									>UFC</label>
								</th>
								<td>
									<input
										type="number"
										id="ufc_1"
										name="ufc_1"
										className="form-control external-text"
										onChange={e => setUfc1(e.target.value)}
										value={ufc1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="ufc_2"
										name="ufc_2"
										className="form-control external-text"
										onChange={e => setUfc2(e.target.value)}
										value={ufc2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="ufc_3"
										name="ufc_3"
										className="form-control external-text"
										onChange={e => setUfc3(e.target.value)}
										value={ufc3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="ufc_average"
										name="ufc_average"
										className="form-control external-text"
										onChange={e => setUfcAverage(e.target.value)}
										value={ufcAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>

							<tr>
								<th scope="row">
									<label
										htmlFor="hygienic_1"
										className="form-label"
									>Calidad higiénica (miles de UFC / ml)</label>
								</th>
								<td>
									<input
										type="number"
										id="hygienic_1"
										name="hygienic_1"
										className="form-control external-text"
										onChange={e => setHygienic1(e.target.value)}
										value={hygienic1}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="hygienic_2"
										name="hygienic_2"
										className="form-control external-text"
										onChange={e => setHygienic2(e.target.value)}
										value={hygienic2}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="hygienic_3"
										name="hygienic_3"
										className="form-control external-text"
										onChange={e => setHygienic3(e.target.value)}
										value={hygienic3}
										step="0.001"
										required
									></input>
								</td>
								<td>
									<input
										type="number"
										id="hygienic_average"
										name="hygienic_average"
										className="form-control external-text"
										onChange={e => setHygienicAverage(e.target.value)}
										value={hygienicAverage}
										step="0.001"
										required
									></input>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="container text-center mb-5">
						<div className="row mb-3">
							<div className="col-12 col-sm-6">
								<button className='btn btn-primary'>Guardar</button>
							</div>
							<div className='col-12 col-sm-6'>
								<Link to='/dashboard-admin/external-quality' className='btn btn-secondary'>Regresar</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default ExternalQualityAdd
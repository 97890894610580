import { useEffect, useState } from "react"
import AxiosHeader from "../utils/AxiosHeader"
import axios from "axios"

const ReportInfoDatalogger = () => {
	const [alert, setAlert] = useState(false)
	const [info, setInfo] = useState([])

	useEffect(() => {
		const getInfoDataloggers = async () => {
			try {
				const token = sessionStorage.getItem('token')
				const config = AxiosHeader(token)
				const {data} = await axios.get(
					`${process.env.REACT_APP_URL_SERVER}/datalogger-info/get-all`,
					config
				)
				if (data.length < 1) {
					setAlert(true)
				}
				setInfo(data)
			} catch (error) {
				console.log(error)
			}
		}
		getInfoDataloggers()
	}, [])

	return (
		<div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
			<div className="form-dashboard">
				<h1 className="h1-dashboard">Reporte información Datalogger</h1>

				{
					alert
					?
					<h2>¡No se encuentran registros!</h2>
					:
					<table className="table table-striped table-hover">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Tanque</th>
								<th scope="col">Volumen</th>
								<th scope="col">Temperatura</th>
								<th scope="col">Fecha</th>
							</tr>
						</thead>
						<tbody>
							{
								info.map((inf, index) => (
									<tr key={index}>
										<th scope="row">{index + 1}</th>
										<td>{inf.name}</td>
										<td>{inf.volume}</td>
										<td>{inf.temperature}</td>
										<td>{inf.created_at}</td>
									</tr>
								))
							}
						</tbody>
					</table>
				}
			</div>
		</div>
	)
}

export default ReportInfoDatalogger
import { useEffect, useState } from "react"
import axios from "axios"
import AxiosHeader from "../utils/AxiosHeader"
import { Link } from "react-router-dom"

const Amount = () => {
    const [amounts, setAmounts] = useState([])

    useEffect(() => {
        const getAmounts = async () => {
            const token = sessionStorage.getItem('token')
            const config = AxiosHeader(token)
            const {data} = await axios.get(
                `${process.env.REACT_APP_URL_SERVER}/user-tank/amount`,
                config
            )
            setAmounts(data)
        }
        getAmounts()
    }, [])

  return (
    <div className="dashboard col-9 col-sm-9 col-md-9 col-xl-10">
    <form className="form-dashboard">
        <h1 className="h1-dashboard">Cantidades en tanques</h1>

        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Código</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Movimiento</th>
                </tr>
            </thead>
            <tbody>
                {
                    amounts.map((amount, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{amount.code}</td>
                            <td>{amount.name}</td>
                            <td>{amount.amount}</td>
                            <td><td><Link to={`/dashboard-admin/movement/${amount.oid}`} className='btn btn-primary'></Link></td></td>
                        </tr>
                    ))
                }
            </tbody>
            </table>
    </form>
</div>
  )
}

export default Amount